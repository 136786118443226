import { match } from 'ts-pattern';
import { useCallback, useEffect, useState } from 'react';

import { Logo } from '@components';

import onboardingBackground from './onboarding-bg.svg';
import { StepIndicator } from './step-indicator';
import { Step as Step1 } from './steps/fields-step';
import { Step as Step2 } from './steps/upload-step';
import { Step as Step3 } from './steps/integrate-step';
import { Step as Step4 } from './steps/contact-info-step';
import { FinalStep } from './steps/final-step';
import { Welcome } from './steps/welcome';
import { Card } from '@mantine/core';
import { useTracker } from '@hooks';
import { BaseTracker } from '../../../../tracking';

const ENDPOINT = 'https://t33jbtp916.execute-api.eu-west-1.amazonaws.com/Prod/incoming';

export type RequestAccessValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  companyWebsite: string;
  useCase: string;
  documentType: string;
  fields: string[];
  source: string;
  destination: string;
  extraInfo?: string;
  email: string;
  files: File[];
};

const getResponse = (data: Partial<RequestAccessValues>) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  companyName: data.companyName,
  companyWebsite: data.companyWebsite,
  documentType: data.documentType,
  source: data.source,
  destination: data.destination,
  fields: data.fields,
  email: data.email,
  extraInfo: data.extraInfo,
});

const sendTrackEvent = (tracker: BaseTracker, step: number, response: Partial<RequestAccessValues>) => {
  if (tracker) {
    const event = match(step)
      .with(0, () => 'Onboarding Started')
      .with(5, () => 'Onboarding Completed')
      .otherwise(() => 'Onboarding Step Completed');

    console.log(`Sending tracking event ${event}`);
    tracker.track(event, { version: 1, step: step, response: getResponse(response) });
  }
};

export function RequestAccessForm() {
  const [formState, setFormState] = useState<Partial<RequestAccessValues>>({});
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState<boolean | undefined>(undefined);
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { tracker } = useTracker();

  useEffect(() => {
    if (step === 0) {
      sendTrackEvent(tracker, 0, {});
    }
  }, [step, tracker]);

  const goToNextStep = (data: Partial<RequestAccessValues>) => {
    setFormState({ ...formState, ...data });
    setStep(step + 1);
    sendTrackEvent(tracker, step + 1, formState);
  };

  const goToPrevStep = (data: any) => {
    setFormState({ ...formState, ...data });
    setStep(step - 1);
  };

  const finishSetup = useCallback(
    (data: Partial<RequestAccessValues>) => {
      const finalState = { ...formState, ...data };
      setFormState(finalState);
      setStep(step + 1);

      Promise.all(
        (finalState.files || []).map((f) => {
          const result = new Promise<{ name: string; content: string }>((resolve) => {
            const reader = new FileReader();

            reader.onload = async () => {
              const res = reader!.result! as string;
              const prefix = Date.now().toString();
              resolve({ name: `${prefix}-${f.name}`, content: res });
            };

            reader.readAsDataURL(f);
          });

          return result;
        })
      )
        .then((files) => {
          fetch(ENDPOINT, {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: JSON.stringify({ ...getResponse(finalState), files: files }),
          })
            .then(() => setSubmittedSuccessfully(true))
            .catch(() => setSubmittedSuccessfully(false));
        })
        .catch(() => setSubmittedSuccessfully(false));
    },
    [formState, step]
  );

  return (
    <div className="tw-bg-[linear-gradient(white, #F4F3FA)] tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-pt-24">
      <div
        style={{
          backgroundImage: `url('${onboardingBackground}')`,
          backgroundPosition: '50% 50%',
        }}
        className="tw-pointer-events-none tw-absolute tw-top-[-50%] tw-h-full tw-w-full  tw-bg-no-repeat"
      />
      <div className="tw-z-10 tw-flex tw-flex-col tw-items-center tw-gap-12">
        <Logo className="tw-h-10" />
        <Card shadow="md" padding="lg" radius="lg" withBorder className="tw-flex tw-w-[35rem] tw-flex-col">
          <div className="tw-flex-1">
            {match({ step, loading })
              .with({ step: 0, loading: false }, () => <Welcome goToNextStep={goToNextStep} />)
              .with({ step: 1, loading: false }, () => (
                <Step1 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} currentValues={formState} />
              ))
              .with({ step: 2, loading: false }, () => (
                <Step2 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} currentValues={formState} />
              ))
              .with({ step: 3, loading: false }, () => (
                <Step3 goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} currentValues={formState} />
              ))
              .with({ step: 4, loading: false }, () => (
                <Step4 goToNextStep={finishSetup} goToPrevStep={goToPrevStep} currentValues={formState} />
              ))
              .with({ step: 5, loading: false }, () => (
                <FinalStep success={submittedSuccessfully} currentValues={formState} />
              ))
              .otherwise(() => null)}
          </div>

          <div className="flex-none">
            {0 < step && step < 6 && (
              <StepIndicator
                startStep={1}
                max={4}
                current={step}
                setCurrent={setStep}
                className="tw-mb-5 tw-mt-5 tw-w-full"
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
