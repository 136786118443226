import { HTMLAttributes } from 'react';
import { useForm, isNotEmpty } from '@mantine/form';

import FlowIcon from '@assets/icon-flow-03.svg?react';
import HelpIcon from '@assets/icon-help-octagon.svg?react';
import CodeIcon from '@assets/icon-code-02.svg?react';

import { RequestAccessValues } from '../form';
import { Select } from '@mantine/core';
import { NextAction } from '../next-action';
import { Header } from '../header';

const SOURCE_INTEGRATION_OPTIONS = [
  { label: 'Email', Icon: FlowIcon },
  {
    label: 'API',
    Icon: CodeIcon,
  },
  {
    label: 'Manual upload',
    Icon: HelpIcon,
  },
  {
    label: 'Automation platforms',
    Icon: HelpIcon,
  },
];

const TARGET_INTEGRATION_OPTIONS = SOURCE_INTEGRATION_OPTIONS;

export type RequestAccessFormStepValues = {
  source: string;
  destination: string;
};

export type RequestAccessFormStepProps = {
  currentValues: Partial<RequestAccessValues>;
  goToNextStep: (data: any) => void;
  goToPrevStep: (data?: any) => void;
} & HTMLAttributes<HTMLDivElement>;

export function Step({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
    validate: {
      source: isNotEmpty('This field is mandatory'),
      destination: isNotEmpty('This field is mandatory'),
    },
  });

  return (
    <div {...rest}>
      <Header title="Setup your workflow" subtitle="How do you intend to receive and export your documents?" />

      <form onSubmit={form.onSubmit(goToNextStep)}>
        <Select
          label="Input trigger"
          description="Where do you get your documents from?"
          placeholder="Choose trigger"
          data={[
            { group: 'Popular', items: ['Email', 'Manual upload', 'REST API'] },
            { group: 'Automation platforms', items: ['PowerAutomate', 'BluePrism', 'UiPath', 'Zapier'] },
          ]}
          key={form.key('source')}
          {...form.getInputProps('source')}
          inputWrapperOrder={['label', 'input', 'description']}
          classNames={{ root: 'tw-my-5' }}
          withAsterisk
        />

        <Select
          label="Export destination"
          description="Where do you want to send them?"
          placeholder="Choose destination"
          data={[
            { group: 'Popular', items: ['Excel', 'Google Sheets', 'Webhook', 'REST API'] },
            { group: 'Automation platforms', items: ['PowerAutomate', 'BluePrism', 'UiPath', 'Zapier'] },
          ]}
          key={form.key('destination')}
          {...form.getInputProps('destination')}
          inputWrapperOrder={['label', 'input', 'description']}
          classNames={{ root: 'tw-my-5' }}
          withAsterisk
        />

        <NextAction text="Next -&gt;" />
      </form>
    </div>
  );
}
