import { Button } from '@components';
import { TextInput, Alert, ActionIcon, Flex, Card, Textarea } from '@mantine/core';
import { HTMLAttributes } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';

import { RequestAccessValues } from '../form';
import { NextAction } from '../next-action';
import { Header } from '../header';

export type RequestAccessFormStepProps = {
  currentValues: Partial<RequestAccessValues>;
  goToNextStep: (data: any) => void;
  goToPrevStep: (data: any) => void;
} & HTMLAttributes<HTMLDivElement>;

export function Step({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value!) ? null : 'Invalid email'),
      firstName: isNotEmpty('This field is mandatory'),
      lastName: isNotEmpty('This field is mandatory'),
      companyWebsite: isNotEmpty('This field is mandatory'),
    },
  });

  return (
    <div {...rest}>
      <Header title="Contact info" subtitle="Tell us who you are and how we can get a hold of you." />

      <form onSubmit={form.onSubmit(goToNextStep)} className="tw-mt-5">
        <div className="tw-my-5 tw-grid tw-grid-cols-2 tw-gap-5">
          <TextInput
            label="First name"
            placeholder="John"
            key={form.key('firstName')}
            {...form.getInputProps('firstName')}
            withAsterisk
          />
          <TextInput
            label="Last name"
            placeholder="Doe"
            key={form.key('lastName')}
            {...form.getInputProps('lastName')}
            withAsterisk
          />
        </div>

        <TextInput
          label="Work email"
          placeholder="john.doe@acme.com"
          className="tw-my-5"
          key={form.key('email')}
          {...form.getInputProps('email')}
          withAsterisk
        />

        <TextInput
          label="Company website"
          placeholder="acme.com"
          key={form.key('companyWebsite')}
          {...form.getInputProps('companyWebsite')}
          withAsterisk
        />

        <Textarea
          label="Additional info"
          description="Feel free to provide any additional info that can be helpful."
          placeholder="I want to automate invoices I receive by email and get them into Excel"
          key={form.key('extraInfo')}
          {...form.getInputProps('extraInfo')}
          classNames={{ root: 'tw-my-5' }}
          inputWrapperOrder={['label', 'input', 'description']}
        />

        <NextAction text="Finish -&gt;" />
      </form>
    </div>
  );
}
