import { ContentType } from '@lucidtech/las-sdk-browser';
import { HTMLProps, useState, useCallback, useEffect } from 'react';
import { twJoin } from 'tailwind-merge';
import { useDropzone } from 'react-dropzone';

import DropzoneIcon from '@assets/dropzone-upload.svg?react';
import { RoundLoader } from '@components';
import { Pill, Text } from '@mantine/core';

export const ACCEPTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/webp'];

export function IdleState() {
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-py-2">
      <DropzoneIcon width="4rem" />
      <div>
        <p className="tw-text-sm tw-text-slate-600">
          Drag documents here or <span className="tw-text-primary-700">browse</span>.
        </p>
        <Text className="!tw-text-center !tw-text-xs !tw-text-gray-500">Accepted: PDF, JPEG, TIFF, PNG, WEBP.</Text>
      </div>
    </div>
  );
}

type IsUploadingProps = {
  currentCount: number;
  totalCount: number;
};
function IsUploading({ currentCount, totalCount }: IsUploadingProps) {
  return (
    <div className="tw-flex tw-content-center tw-items-center tw-gap-2 tw-self-center tw-text-gray-800">
      <RoundLoader className="tw-w-6 tw-text-gray-500" /> Uploaded {currentCount} of {totalCount} files
    </div>
  );
}

export type DropzoneProps = {
  files?: File[];
  onDrop: (files: File[]) => void;
} & HTMLProps<HTMLInputElement>;

export function Dropzone({ className, files, onDrop }: DropzoneProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>(files || []);

  const { getInputProps, getRootProps } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    disabled: false,
    multiple: true,
    onDrop: async (files) => {
      setSelectedFiles((old) => [...old, ...files]);
      onDrop(files);
    },
  });

  const removeFile = (file: File) => setSelectedFiles(selectedFiles.filter((f) => f !== file));

  return (
    <>
      <div
        className={twJoin(
          'tw-flex tw-min-h-[100px] tw-justify-center tw-rounded-lg tw-border-2 tw-border-dashed tw-border-gray-200 tw-bg-white',
          className
        )}
        {...getRootProps()}
      >
        {isUploading ? <IsUploading currentCount={1} totalCount={2} /> : <IdleState />}
        <input {...getInputProps()} />
      </div>

      <div>
        {selectedFiles.map((file) => (
          <Pill withRemoveButton onRemove={() => removeFile(file)}>
            {file.name}
          </Pill>
        ))}
      </div>
    </>
  );
}
