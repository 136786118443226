import { Button } from '@components';
import { Group, Text, Badge, Accordion } from '@mantine/core';
import ClockCheck from './clock-check.svg?react';
import classes from './welcome.module.css';

const steps = [
  {
    label: 'Fill out use case details',
    description:
      'What type of documents do you want to automate and how would you like to integrate it in your workflow?',
    timeToComplete: '5 min',
  },
  {
    label: "We'll manually review your submission",
    description:
      "We don’t want to waste your time, so we’ll manually evaluate your use case to see if it's a good fit.",
  },
  {
    label: 'Free onboarding session',
    description: 'We’ll set up your account to your needs and show you how it works.',
    timeToComplete: '15 min',
  },
];

type AccordionLabelProps = {
  step: number;
  label: string;
  description: string;
  timeToComplete?: string;
  'aria-first': boolean;
  'aria-last': boolean;
};

function AccordionLabel({ step, label, description, timeToComplete, ...rest }: AccordionLabelProps) {
  return (
    <Group wrap="nowrap" className="tw-px-0 tw-py-1" {...rest}>
      <div className="flex tw-h-[2rem] tw-w-[2rem] tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-[#84CAFF] tw-font-bold tw-text-blue-500">
        <span className="tw-text-md">{step}</span>
      </div>

      <div className="tw-flex-grow">
        <Text size="sm" fw={500}>
          {label}
        </Text>
      </div>

      {timeToComplete && (
        <Badge variant="light" color="gray">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <ClockCheck />
            {timeToComplete}
          </div>
        </Badge>
      )}
    </Group>
  );
}

export function Welcome({ goToNextStep }: { goToNextStep: (data: any) => void }) {
  const items = steps.map((item, index) => (
    <Accordion.Item value={index.toString()} key={item.label}>
      <Accordion.Control>
        <AccordionLabel step={index + 1} aria-first={index === 0} {...item} aria-last={index === steps.length - 1} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm" c="dimmed">
          {item.description}
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div>
      <h2>Get started for free</h2>
      <p className="tw-mb-5 tw-text-sm tw-text-slate-500">
        Provide some information about your use case, and we'll set up your account for you to make sure you get a
        flying start.
      </p>

      <div className="tw-my-6">
        <Accordion defaultValue={'0'} classNames={classes}>
          {items}
        </Accordion>
      </div>

      <Button className="tw-w-full" onClick={goToNextStep}>
        Get Started -&gt;
      </Button>
    </div>
  );
}
