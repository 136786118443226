import { useState, HTMLAttributes } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';

import { Autocomplete, TagsInput } from '@mantine/core';
import { RequestAccessValues } from '../form';
import { NextAction } from '../next-action';
import { Header } from '../header';

const DOCUMENT_TYPE_OPTIONS = [
  'Invoices',
  'Receipts',
  'Purchase Orders',
  'Order Confirmations',
  'ID Cards',
  'Bills of Lading',
];

export type RequestAccessFormStepValues = {
  useCase: string;
  documentCount: string;
  documentType: string;
  otherDocumentType: string;
};

export type RequestAccessFormStepProps = {
  goToNextStep: (data: any) => void;
  goToPrevStep: (data: any) => void;
  currentValues: Partial<RequestAccessValues>;
} & HTMLAttributes<HTMLDivElement>;

export function Step({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
    validate: {
      documentType: isNotEmpty('This field is mandatory.'),
      fields: isNotEmpty('This field is mandatory'),
    },
  });

  const [files, setFiles] = useState<File[]>([]);

  return (
    <div {...rest}>
      <Header title="Define your AI model" subtitle="Tell us about the documents you want to automate." />

      <form onSubmit={form.onSubmit(goToNextStep)}>
        <Autocomplete
          label="What type of documents are you looking to automate?"
          placeholder="Choose a document type or enter anything"
          data={DOCUMENT_TYPE_OPTIONS}
          key={form.key('documentType')}
          {...form.getInputProps('documentType')}
          classNames={{ label: 'tw-my-3' }}
          withAsterisk
        />

        <TagsInput
          label="Which fields do you want to extract?"
          placeholder="For example total amount, supplier name, etc."
          description="Press Enter to add fields."
          splitChars={[',', '|']}
          key={form.key('fields')}
          {...form.getInputProps('fields')}
          classNames={{ label: 'tw-my-3' }}
          styles={{
            inputField: { border: 'none' },
            input: { padding: 0, height: '6rem' },
          }}
          inputWrapperOrder={['label', 'input', 'description']}
          withAsterisk
        />

        <NextAction text="Next -&gt;" />
      </form>
    </div>
  );
}
