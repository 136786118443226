import { useState, HTMLAttributes } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';

import { InputWrapper } from '@mantine/core';
import { RequestAccessValues } from '../form';
import { Dropzone } from '../dropzone';
import { NextAction } from '../next-action';
import { Header } from '../header';

const DOCUMENT_TYPE_OPTIONS = [
  'Invoices',
  'Receipts',
  'Purchase Orders',
  'Order Confirmations',
  'ID Cards',
  'Bills of Lading',
];

export type RequestAccessFormStepValues = {
  useCase: string;
  documentCount: string;
  documentType: string;
  otherDocumentType: string;
};

export type RequestAccessFormStepProps = {
  goToNextStep: (data: any) => void;
  goToPrevStep: (data: any) => void;
  currentValues: Partial<RequestAccessValues>;
} & HTMLAttributes<HTMLDivElement>;

export function Step({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
  });

  return (
    <div {...rest}>
      <Header
        title="Upload sample documents"
        subtitle="Feel free to upload a few samples to help us better understand your use case."
      />

      <form onSubmit={form.onSubmit(goToNextStep)}>
        <InputWrapper className="tw-my-5">
          <Dropzone
            files={form.getValues()['files']}
            onDrop={(f) => form.setValues({ files: f! as File[] })}
            className="tw-my-8 tw-h-[20vh]"
          />
        </InputWrapper>

        <NextAction text="Next -&gt;" withSkip />
      </form>
    </div>
  );
}
