import { HTMLAttributes } from 'react';

export type StepIndicatorProps = {
  max: number;
  current: number;
  startStep: number;
  setCurrent: (p: number) => void;
} & HTMLAttributes<HTMLDivElement>;

export function StepIndicator({ max, current, startStep, setCurrent, ...rest }: StepIndicatorProps) {
  return (
    <div {...rest} className="tw-mt-5 tw-flex tw-w-full tw-justify-center tw-gap-1">
      {Array.from({ length: max }).map((_, index) => {
        return (
          <div
            key={index}
            aria-selected={index + startStep === current}
            onClick={() => setCurrent(startStep + index)}
            className="tw-h-[0.7rem] tw-w-[0.7rem] tw-rounded-full aria-[selected=false]:tw-bg-gray-200 aria-[selected=true]:tw-bg-primary-500"
          />
        );
      })}
    </div>
  );
}
